import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'


const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('all');
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
        I've been involved in a variety of projects, showcasing my versatility across different domains. Here's a glimpse of some of the projects I've worked on.
        </Desc>
        <ToggleButtonGroup >
          {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>All</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>All</ToggleButton>
          }
          <Divider />
          {toggle === 'web app' ?
            <ToggleButton active value="web app" onClick={() => setToggle('web app')}>WEB APPLICATIONS</ToggleButton>
            :
            <ToggleButton value="web app" onClick={() => setToggle('web app')}>WEB APPLICATIONS</ToggleButton>
          }
          {/* <Divider /> */}
          {/* {toggle === 'SpringBoot apps' ?
            <ToggleButton active value="springboot app" onClick={() => setToggle('springboot app')}>SpringBoot APPLICATIONS</ToggleButton>
            :
            <ToggleButton value="springboot app" onClick={() => setToggle('springboot app')}>SpringBoot APPLICATIONS</ToggleButton>
          } */}
          <Divider />
          {toggle === 'DSA TOPICS' ?
            <ToggleButton active value="dsa topics" onClick={() => setToggle('dsa topics')}>DSA TOPICS</ToggleButton>
            :
            <ToggleButton value="dsa topics" onClick={() => setToggle('dsa topics')}>DSA TOPICS</ToggleButton>
          }
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'all' && projects
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          {projects
            .filter((item) => item.category == toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Projects